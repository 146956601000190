import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { getAllLocations } from "../../services/locations";
import _ from "lodash";
import { createBooking, BookingType } from "../../services/bookings";
import { AxiosResponse } from "axios";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "./index.css";
import { ReactComponent as Cross } from "../../assets/icons/cross-black.svg";

interface EditTripModalType {
  isModalVisible: boolean;
  onClose: any;
  bookingDetails: BookingType;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingType>>;
}

export interface formDataType {
  tripType: "one_way" | "round_trip" | "none";
  dropOffLocation: any;
  pickUpLocation: any;
  passengers: number;
  pickUpTime: any;
  pickUpDate: any;
  returnPickUpLocation: any;
  returnDropOffLocation: any;
  returnPickUpTime: any | null;
  returnPickUpDate: any;
  returnPassengers: number | null;
  luggagePieces: number;
  returnLuggagePieces: number | null;
}

const convertTripTimeToTimeObject = (time: string) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const dateTimeString = `${currentDate}T${time}:00`;
  const momentDate = moment(dateTimeString);

  if (!momentDate.isValid()) {
    return moment().format("YYYY-MM-DDTHH:mm:ss");
  }

  return momentDate.format("YYYY-MM-DDTHH:mm:ss");
};

const EditTripModal: React.FC<EditTripModalType> = (props) => {
  const [pickUpLocations, setPickupLocations] = useState<any[]>([]);
  const [dropOffLocations, setDropoffLocations] = useState<any[]>([]);
  const [formData, setFormData] = useState<formDataType>({
    tripType: props.bookingDetails.tripType,
    dropOffLocation: props.bookingDetails.dropOffLocation,
    passengers: props.bookingDetails.passengers,
    pickUpDate: props.bookingDetails.pickUpDate || null,
    pickUpLocation: props.bookingDetails.pickUpLocation,
    pickUpTime: props.bookingDetails.pickUpTime ? convertTripTimeToTimeObject(props.bookingDetails.pickUpTime) : '',
    returnPickUpLocation: props.bookingDetails.returnPickUpLocation,
    returnDropOffLocation: props.bookingDetails.returnDropOffLocation,
    returnPickUpTime: props.bookingDetails.returnPickUpTime ? convertTripTimeToTimeObject(props.bookingDetails.returnPickUpTime) : '',
    returnPickUpDate: props.bookingDetails.returnPickUpDate || null,
    returnPassengers: props.bookingDetails.returnPassengers,
    luggagePieces: props.bookingDetails.luggagePieces,
    returnLuggagePieces: props.bookingDetails.returnLuggagePieces
  });

  const resetFormData = () => {
    setFormData({
      tripType: props.bookingDetails.tripType,
      dropOffLocation: props.bookingDetails.dropOffLocation,
      passengers: props.bookingDetails.passengers,
      pickUpDate: props.bookingDetails.pickUpDate,
      pickUpLocation: props.bookingDetails.pickUpLocation,
      pickUpTime: convertTripTimeToTimeObject(props.bookingDetails.pickUpTime),
      returnPickUpLocation: props.bookingDetails.returnPickUpLocation,
      returnDropOffLocation: props.bookingDetails.returnDropOffLocation,
      returnPickUpTime: props.bookingDetails.returnPickUpTime ? convertTripTimeToTimeObject(props.bookingDetails.returnPickUpTime) : null,
      returnPickUpDate: props.bookingDetails.returnPickUpDate,
      returnPassengers: props.bookingDetails.returnPassengers,
      luggagePieces: props.bookingDetails.luggagePieces,
      returnLuggagePieces: props.bookingDetails.returnLuggagePieces
    })
  }

  const validateFormData = () => {
      const errors = [];

      if (!formData.tripType) {
          errors.push("Trip type is required.");
      }
      if (!formData.dropOffLocation) {
          errors.push("Drop-off location is required.");
      }
      if (!formData.pickUpDate) {
          errors.push("Pick-up date is required.");
      }
      if (!formData.pickUpTime) {
          errors.push("Pick-up time is required.");
      }
      if (!formData.pickUpLocation) {
          errors.push("Pick-up location is required.");
      }
      if (formData.passengers === undefined || formData.passengers === null) {
          errors.push("Number of passengers is required.");
      } else if (formData.passengers < 1 || formData.passengers > 16) {
          errors.push("Number of passengers must be between 1 and 16.");
      }
      if (formData.luggagePieces === undefined || formData.luggagePieces === null) {
          errors.push("Number of luggage pieces is required.");
      }

      if (formData.pickUpLocation === formData.dropOffLocation) {
          errors.push("Pick-up and drop-off locations can't be the same.");
      }

      // Check return fields if trip type is not "one_way"
      if (formData.tripType !== "one_way") {
          if (!formData.returnPickUpLocation) {
              errors.push("Return pick-up location is required.");
          }
          if (!formData.returnDropOffLocation) {
              errors.push("Return drop-off location is required.");
          }
          if (!formData.returnPickUpDate) {
              errors.push("Return pick-up date is required.");
          }
          if (!formData.returnPickUpTime) {
              errors.push("Return pick-up time is required.");
          }
          if (formData.returnPassengers === undefined || formData.returnPassengers === null) {
              errors.push("Number of return passengers is required.");
          } else if (formData.returnPassengers < 1 || formData.returnPassengers > 16) {
              errors.push("Number of return passengers must be between 1 and 16.");
          }
          if (formData.returnLuggagePieces === undefined || formData.returnLuggagePieces === null) {
              errors.push("Number of return luggage pieces is required.");
          }
          if (formData.returnPickUpLocation === formData.returnDropOffLocation) {
              errors.push("Return pick-up and drop-off locations can't be the same.");
          }
      }

      return errors;
  }

  const closeModal = () => {
    props.onClose();
    resetFormData();
  }

  const saveTrip = () => {

    const errors = validateFormData();
    if(errors.length !== 0){
      alert(errors.join("\n"));
      return;
    }

    try {
      const momentPickUpDateObject = moment(formData.pickUpDate, "YYYY-MM-[W]E");
      const momentPickUpTimeObject = moment(formData.pickUpTime);
      const momentReturnPickUpDateObject = moment(formData.returnPickUpDate, "YYYY-MM-[W]E");
      const momentReturnPickUpTimeObject = moment(formData.returnPickUpTime);

      // Verify datetimes are valid before formatting
      const formattedPickUpDate = momentPickUpDateObject.isValid() ? momentPickUpDateObject.format("YYYY-MM-DD") : '';
      const formattedReturnPickUpDate = momentReturnPickUpDateObject.isValid() ? momentReturnPickUpDateObject.format("YYYY-MM-DD") : '';
      const formattedPickUpTime = momentPickUpTimeObject.isValid() ? momentPickUpTimeObject.format("HH:mm") : '';
      const formattedReturnPickUpTime = momentReturnPickUpTimeObject.isValid() ? momentReturnPickUpTimeObject.format("HH:mm") : '';

      props.setBookingDetails({
        ...props.bookingDetails,
        tripType: formData.tripType,
        dropOffLocation: formData.dropOffLocation,
        pickUpDate: formattedPickUpDate,
        pickUpTime: formattedPickUpTime,
        pickUpLocation: formData.pickUpLocation,
        returnPickUpLocation: formData.returnPickUpLocation,
        returnDropOffLocation: formData.returnDropOffLocation,
        returnPickUpDate: formattedReturnPickUpDate,
        returnPickUpTime: formattedReturnPickUpTime,
        passengers: formData.passengers,
        returnPassengers: formData.returnPassengers,
        luggagePieces: formData.luggagePieces,
        returnLuggagePieces: formData.returnLuggagePieces,
      });
    } catch (e: any) {
      console.log(e);
    } finally {
      props.onClose()
    }
  }

  useEffect(() => {
    resetFormData();
  }, [props.bookingDetails])

  useEffect(() => {
    getAllLocations().then((response) => {
      if (!_.isEmpty(response) || !_.isEmpty(response.data.data)) {
        let locations: any[] = response.data.data;

        let pickUpLocations: any[] = Array.from(new Set(locations.map(location => location.pickUpLocation)))
          .map(location => ({
            value: location,
            label: location,
          }));

        let dropOffLocations: any[] = Array.from(new Set(locations.map(location => location.dropOffLocation)))
          .map(location => ({
            value: location,
            label: location,
          }));

        setPickupLocations(pickUpLocations);
        setDropoffLocations(dropOffLocations);
      }
    });
  }, []);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!_.isEmpty(props.bookingDetails) || !_.isUndefined(props.bookingDetails)) {
      createBooking(props.bookingDetails)
        .then((response: AxiosResponse) => {
          const restrcutredResponse: any = response.data;
          toast.success(restrcutredResponse.message, {
            position: "bottom-right",
          });
        })
        .catch((error: any) => {
          const response: any = error.response.data;
          toast.error(response.message, { position: "bottom-right" });
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        open={props.isModalVisible}
        onClose={closeModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
        }}
      >
        <Box
          component="form"
          onSubmit={saveTrip}
          sx={{
            display:"flex",
            flexDirection: "column",
            backgroundColor: "white",
            padding: "16px",
            borderRadius: "8px",
            width: "calc(100% - 16px)",
            height: { xs: "calc(100% - 32px)",  sm:"auto"},
            maxWidth: "800px",
            boxShadow: "0px 4px 25px rgba(0,0,0, 0.25)",
          }}
        >
          <ToastContainer />
          <Box sx={{width: '100%', display: 'flex', justifyContent: 'end'}}>
            <Cross type="button" style={{cursor: "pointer", padding: "4px"}} height={16} width={16} onClick={closeModal}>
              Close
            </Cross>
          </Box>

          <Box sx={{
            marginBottom: "8px",
            overflowY: "auto",
          }}>
            <div>
              <h3 style={{color: "#341ea0"}}>Outward Journey</h3>
            </div>

            <Box sx={{
              display: "flex",
              flexDirection: {xs: "column", sm: "row"},
              gap: "8px",
              marginTop: "8px"
            }}>
              <Box sx={{
                width: {xs: "84%", sm: "50%"}
              }}>
                <InputLabel>Pickup Location</InputLabel>
                <Autocomplete
                  disablePortal
                  options={pickUpLocations}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value) =>
                    setFormData({ ...formData, pickUpLocation: value?.value || "" })
                  }
                  value={formData.pickUpLocation}
                />
              </Box>

              <Box sx={{
                width: {xs: "84%", sm: "50%"}
              }}>
                <InputLabel>Dropoff Location</InputLabel>
                <Autocomplete
                  disablePortal
                  options={dropOffLocations}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value) =>
                    setFormData({ ...formData, dropOffLocation: value?.value || "" })
                  }
                  value={formData.dropOffLocation}
                />
              </Box>

            </Box>

            <Box sx={{
              display: "flex",
              flexDirection: {xs: "column", sm: "row"},
              gap: "8px",
              marginTop: "8px"
            }}>

              <Box sx={{
                width: {xs: "100%", sm: "25%"}
              }}>
                <InputLabel>Pickup Time</InputLabel>
                <DateTimePicker
                  className="edit-trip-datetimepicker"
                  onChange={(value) => setFormData({ ...formData, pickUpTime: value })}
                  value={formData.pickUpTime || null}
                  format="HH:mm"
                  disableCalendar
                />
              </Box>

              <Box sx={{
                width: {xs: "100%", sm: "25%"}
              }}>
                <InputLabel>Pickup Date</InputLabel>
                <DateTimePicker
                  className="edit-trip-datetimepicker"
                  onChange={(value) => setFormData({ ...formData, pickUpDate: value })}
                  value={formData.pickUpDate || null}
                  format="y-MM-dd"
                  disableClock
                  minDate={new Date()}
                />
              </Box>

              <Box sx={{
                width: {xs: "100%", sm: "25%"}
              }}>
                <InputLabel>Passengers</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, passengers: +event.target.value })
                  }
                  value={formData.passengers || ""}
                />
              </Box>
              <Box sx={{
                width: {xs: "100%", sm: "25%"}
              }}>
                <InputLabel>Luggage</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, luggagePieces: +event.target.value })
                  }
                  value={formData.luggagePieces || ""}
                />
              </Box>
            </Box>

            <Box sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              paddingTop: "16px",
            }}>
              <h3 style={{color: "#341ea0"}}>Return Journey</h3>
              <Checkbox
                checked={formData.tripType == 'round_trip' || formData.tripType == 'none'}
                onChange={(event, value) =>
                  setFormData({ ...formData, tripType: value ? "round_trip"  : "one_way" })
                }
              />
            </Box>

            <Box sx={{
              display: (formData.tripType == 'round_trip' || formData.tripType == 'none') ? "flex" : "none",
              flexDirection: {xs: "column", sm: "row"},
              gap: "8px",
              marginTop: "8px"
            }}>
              <Box sx={{ width: {xs: "84%", sm: "50%"} }}>
                <InputLabel>Pickup Location</InputLabel>
                <Autocomplete
                  disablePortal
                  options={pickUpLocations}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value) =>
                    setFormData({ ...formData, returnPickUpLocation: value?.value || "" })
                  }
                  value={formData.returnPickUpLocation}
                />
              </Box>

              <Box sx={{ width: {xs: "84%", sm: "50%"} }}>
                <InputLabel>Dropoff Location</InputLabel>
                <Autocomplete
                  disablePortal
                  options={dropOffLocations}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(event, value) =>
                    setFormData({ ...formData, returnDropOffLocation: value?.value || "" })
                  }
                  value={formData.returnDropOffLocation}
                />
              </Box>
            </Box>

            <Box sx={{
              display: (formData.tripType == 'round_trip' || formData.tripType == 'none') ? "flex" : "none",
              flexDirection: {xs: "column", sm: "row"},
              gap: "8px",
              marginTop: "8px"
            }}>
              <Box sx={{ width: {xs: "100%", sm: "25%"} }}>
                <InputLabel>Pickup Time</InputLabel>
                <DateTimePicker
                  className="edit-trip-datetimepicker"
                  onChange={(value) => setFormData({ ...formData, returnPickUpTime: value })}
                  value={formData.returnPickUpTime || null}
                  format="HH:mm"
                  disableCalendar
                />
              </Box>

              <Box sx={{ width: {xs: "100%", sm: "25%"} }}>
                <InputLabel>Pickup Date</InputLabel>
                <DateTimePicker
                  className="edit-trip-datetimepicker"
                  onChange={(value) => setFormData({ ...formData, returnPickUpDate: value })}
                  value={formData.returnPickUpDate || null}
                  format="y-MM-dd"
                  disableClock
                  minDate={new Date()}
                />
              </Box>

              <Box sx={{ width: {xs: "100%", sm: "25%"} }}>
                <InputLabel>Passengers</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, returnPassengers: +event.target.value })
                  }
                  value={formData.returnPassengers || ""}
                />
              </Box>

              <Box sx={{ width: {xs: "100%", sm: "25%"} }}>
                <InputLabel>Luggage</InputLabel>
                <TextField
                  type="number"
                  onChange={(event) =>
                    setFormData({ ...formData, returnLuggagePieces: +event.target.value })
                  }
                  value={formData.returnLuggagePieces || ""}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{
            marginTop: "auto",
            paddingTop: "16px"
          }}>
            <Button
              type="button"
              onClick={saveTrip}
              style={{
                background: "#341ea0",
                color: "white",
                padding: "8px 16px 8px 16px",
                borderRadius: "8px"
              }}
            >
              Done
            </Button>
          </Box>

        </Box>
      </Modal>
    </>
  );
};

export default EditTripModal;
