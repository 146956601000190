import React, { useEffect } from "react";
import PassengerDetailSummary from "../../components/passenger-detail-summary";
import { Grid } from "@mui/material";
import PassengerDetails from "../../components/passenger-details-form";
import YourTransfer from "../../components/your-transfer";
import ExtrasComponent from "../../components/extras-component";
import ProgressStepper from "../../components/progress-stepper";
import { useState } from "react";
import "./index.css";
import {
  getRateFromLocation,
  RatesFromLocationType,
} from "../../services/rates";
import { AxiosResponse } from "axios";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import BookingSummaryComponent from "../../components/booking-summary";
import { PassengerDetailsType } from "../../services/passengers-details";
import { PassengerDetailExtrasType } from "../../services/passengers-detail-extras";
import { convertToValidTimeString } from "../../utls/time-formater";
import { BookingType } from "../../services/bookings";
import HeaderComponent from "../../components/header";
import FooterComponent from "../../components/footer";
import { Nightlife } from "@mui/icons-material";

const RenderStepperComponents: React.FC<{
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  setPassengerDetails: React.Dispatch<
    React.SetStateAction<
      Omit<PassengerDetailsType, "id" | "isDelete"> | undefined
    >
  >;
  passengers: any;
  returnPassengers: any;
  passengerDetails: Omit<PassengerDetailsType, "id" | "isDelete"> | undefined;
  setPassengerExtrasDetails: React.Dispatch<
    React.SetStateAction<
      Omit<PassengerDetailExtrasType, "id" | "isDelete"> | undefined
    >
  >;
  setPassengerId: React.Dispatch<React.SetStateAction<number>>;
  passengerExtrasDetails:
    | Omit<PassengerDetailExtrasType, "id" | "isDelete">
    | undefined;
  passengerId: number;
  bookingDetails: BookingType;
  setBookingDetails: React.Dispatch<React.SetStateAction<BookingType>>;
}> = (props) => {
  switch (props.activeStep) {
    case 0:
      return (
        <PassengerDetails
          key="passenger-details-step"
          setActiveStep={props.setActiveStep}
          activeStep={props.activeStep}
          setPassengerId={props.setPassengerId}
          setPassengerDetails={props.setPassengerDetails}
          passengers={props.passengers}
          returnPassengers={props.returnPassengers}
        />
      );
    case 1:
      return (
        <ExtrasComponent
          setActiveStep={props.setActiveStep}
          activeStep={props.activeStep}
          passengerId={props.passengerId}
          setPassengerExtrasDetails={props.setPassengerExtrasDetails}
          bookingDetails={props.bookingDetails}
          setBookingDetails={props.setBookingDetails}
        />
      );
    case 2:
      return (
        <BookingSummaryComponent
          setActiveStep={props.setActiveStep}
          activeStep={props.activeStep}
          bookingDetails={props.bookingDetails}
          passengerDetails={props.passengerDetails}
          passengerExtrasDetails={props.passengerExtrasDetails}
          passengerId={props.passengerId}
        />
      );
  }
  return <></>;
};

const Home: React.FC = () => {
  const bookingInitalType: BookingType = {
    pickUpLocation: "",
    dropOffLocation: "",
    pickUpDate: "",
    pickUpTime: "",
    passengers: 0,
    luggagePieces: 0,
    price: "",
    pickUpLandMark: "",
    rateId: 0,
    returnPickUpLocation: "",
    returnDropOffLocation: "",
    returnPickUpDate: "",
    returnPickUpTime: "",
    returnPassengers: 0,
    returnLuggagePieces: 0,
    returnPrice: "",
    returnPickUpLandMark: "",
    tripType: "one_way",
    returnRateId: 0,
    passengerId: 0,
  };

  const [activeStep, setActiveStep] = useState<number>(0);
  const [bookingPrice, setBookingPrice] = useState<string>("");
  const [passengerDetails, setPassengerDetails] =
    useState<Omit<PassengerDetailsType, "id" | "isDelete">>();
  const [passengerExtraDetails, setPassengerExtraDetails] =
    useState<Omit<PassengerDetailExtrasType, "id" | "isDelete">>();
  const [passengerId, setPassengerId] = useState<number>(0);
  const [bookingDetails, setBookingDetails] =
    useState<BookingType>(bookingInitalType);

  // Extract ALL parameters from URL
  const [searchParams] = useSearchParams();
  
  // Outbound Trip Parameters
  const pickUpLocation = searchParams.get("pickUpLocation");
  const dropOffLocation = searchParams.get("dropOffLocation");
  const pickUpDate = searchParams.get("pickUpDate");
  const pickUpTime = searchParams.get("pickUpTime");
  const luggagePieces = searchParams.get("luggagePieces");
  const passengers = searchParams.get("passengers");

  // Return Trip Parameters
  const returnPickUpLocation = searchParams.get("returnPickUpLocation");
  const returnDropOffLocation = searchParams.get("returnDropOffLocation");
  const returnPickUpDate = searchParams.get("returnPickUpDate");
  const returnPickUpTime = searchParams.get("returnPickUpTime");
  const returnLuggagePieces = searchParams.get("returnLuggagePieces");
  const returnPassengers = searchParams.get("returnPassengers");
  
  // Trip Type (determine round trip or one-way)
  const tripType: any = searchParams.get("tripType");

  const requestRatesForTrip = async (params: RatesFromLocationType) : Promise<Array<number> | null> => {
    try {
      const response: AxiosResponse = await getRateFromLocation(params);
      const restructuredResponse: any = response.data;
      return [restructuredResponse.data.price, restructuredResponse.data.id];
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || 'An error occurred while fetching rates.';
      toast.error(errorMessage, { position: "bottom-right" });
      return null;
    }
  }

  const getRatesForLocation = async () => {
    console.log("Fetching location rates");
    let totalPrice: number | string = 'CUSTOM QUOTE';

    let departPrice: number | null = null;
    let departRateId: number | null = null;

    let returnPrice: number | null = null;
    let returnRateId: number | null = null;

    // Check if all the parameters for departure
    if (
      !_.isNull(bookingDetails.pickUpLocation) &&
      !_.isNull(bookingDetails.dropOffLocation) &&
      !_.isNull(bookingDetails.passengers) &&
      !_.isNull(bookingDetails.pickUpTime)
    ) {
      const depertureParams: RatesFromLocationType = {
        fromLocation: bookingDetails.pickUpLocation,
        toLocation: bookingDetails.dropOffLocation,
        passengerCount: Number(bookingDetails.passengers),
        tripType: 'one_way',
        pickUpTime:
          pickUpTime === "PACKAGE_RATE"
            ? pickUpTime
            : convertToValidTimeString(bookingDetails.pickUpTime),
      };

      // Get depart trip rate
      const response = await requestRatesForTrip(depertureParams);
      if(response){        
        departPrice = response[0];
        departRateId = response[1];
      }
    }

    // Check if all the parameters for return trip are present
    if(
      bookingDetails.tripType == "round_trip" &&
      !_.isNull(bookingDetails.returnPickUpLocation) &&
      !_.isNull(bookingDetails.returnDropOffLocation) &&
      !_.isNull(bookingDetails.returnPassengers) &&
      !_.isNull(bookingDetails.returnPickUpTime)
    ) {
      const returnParams : RatesFromLocationType = {
        fromLocation: bookingDetails.returnPickUpLocation,
        toLocation: bookingDetails.returnDropOffLocation,
        passengerCount: Number(bookingDetails.returnPassengers),
        tripType: "one_way",
        pickUpTime:
          returnPickUpTime === "PACKAGE_RATE"
            ? returnPickUpTime
            : convertToValidTimeString(bookingDetails.returnPickUpTime),
      };

      // Get return trip rate
      const response = await requestRatesForTrip(returnParams);
      if(response){        
        returnPrice = response[0];
        returnRateId = response[1];
      }
    }

    if(bookingDetails.tripType == "one_way" && departPrice && departRateId){
      totalPrice = departPrice;
    } else if(bookingDetails.tripType == "round_trip" && departPrice && departRateId && returnPrice && returnRateId){
      totalPrice = departPrice + returnPrice;
    }

    if(totalPrice == "CUSTOM QUOTE"){
      setBookingDetails({
        ...bookingDetails,
        pickUpLocation: "None",
        dropOffLocation: "None",
        luggagePieces: 0,
        passengers: 0,
        pickUpDate: "",
        pickUpTime: "",
        price: "CUSTOM QUOTE",
        returnPickUpLocation: "None",
        returnDropOffLocation: "None",
        returnLuggagePieces: 0,
        returnPassengers: 0,
        returnPickUpDate: "",
        returnPickUpTime: "",
        returnPrice: "CUSTOM QUOTE",
        rateId: 0,
        tripType: 'none',
      });

      setBookingPrice(`CUSTOM QUOTE`);
    } else {
      setBookingDetails({
        ...bookingDetails,
        price: String(departPrice),
        rateId: departRateId ?? 0,
        returnPrice: String(returnPrice),
        returnRateId: returnRateId ?? 0,
      });
      setBookingPrice(`€ ${totalPrice}`);
    }
  };

  useEffect(() => {
    setBookingDetails({
      ...bookingDetails,
      pickUpLocation: pickUpLocation ?? '',
      dropOffLocation: dropOffLocation ?? '',
      luggagePieces: Number(luggagePieces),
      passengers: Number(passengers ?? 0),
      pickUpDate: String(pickUpDate ?? ''),
      pickUpTime: String(pickUpTime ?? ''),
      returnPickUpLocation: returnPickUpLocation ?? '',
      returnDropOffLocation: returnDropOffLocation ?? '',
      returnLuggagePieces: Number(returnLuggagePieces),
      returnPassengers: Number(returnPassengers),
      returnPickUpDate: String(returnPickUpDate ?? ''),
      returnPickUpTime: String(returnPickUpTime ?? ''),
      passengerId: passengerId ?? null,
      tripType: tripType ?? 'none',
    });
  }, [
    pickUpLocation,
    dropOffLocation,
    passengers,
    returnPickUpLocation,
    returnDropOffLocation,
    returnPassengers,
    passengerId,
    tripType,
  ]);

  useEffect(() => {
    getRatesForLocation();
  }, [
    bookingDetails.pickUpLocation,
    bookingDetails.dropOffLocation,
    bookingDetails.passengers,
    bookingDetails.pickUpDate,
    bookingDetails.pickUpTime,
    bookingDetails.returnPickUpLocation,
    bookingDetails.returnDropOffLocation,
    bookingDetails.returnPassengers,
    bookingDetails.returnPickUpDate,
    bookingDetails.returnPickUpTime,
    bookingDetails.tripType,
  ]);

  return (
    <>
      <ToastContainer />

      <div>
        <HeaderComponent />
        <ProgressStepper activeStep={activeStep} />
      </div>

      <div className="home-container">
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3} direction="column">
              <Grid item md={8} xs={12}>
                <PassengerDetailSummary
                  bookingPrice={bookingPrice}
                  luggagePieces={luggagePieces}
                  passengerCount={passengers}
                />
              </Grid>
              <Grid item md={8} xs={12} order={{ xs: 2, sm: 2 }}>
                <RenderStepperComponents
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  setPassengerDetails={setPassengerDetails}
                  passengers={passengers}
                  returnPassengers={returnPassengers}
                  passengerDetails={passengerDetails}
                  setPassengerExtrasDetails={setPassengerExtraDetails}
                  passengerExtrasDetails={passengerExtraDetails}
                  passengerId={passengerId}
                  setPassengerId={setPassengerId}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4} xs={12} order={{ xs: 3, sm: 3 }}>
            <YourTransfer
              activeStep={activeStep}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
            />
          </Grid>
        </Grid>
      </div>
      <FooterComponent />
    </>
  );
};

export default Home;
